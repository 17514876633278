import { Box } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Twitter } from "@material-ui/icons";
import { GetValueForKey } from "../../utils/helper";
import { TEXTS_MAPPING } from "../../utils/textMapping";
import Footer from "../../components/Footer";
import { Typography } from "@material-ui/core";

export default function ContactUs() {
  const companyMail = GetValueForKey(
    TEXTS_MAPPING.COMPANY_EMAIL,
    "text",
    true
  )?.[0];

  const companyPhone = GetValueForKey(
    TEXTS_MAPPING.COMPANY_PHONE,
    "text",
    true
  )?.[0];

  const companyAddress = GetValueForKey(
    TEXTS_MAPPING.COMPANY_ADDRESS,
    "text",
    true
  )?.[0];

  const companyTwitter = GetValueForKey(
    TEXTS_MAPPING.COMPANY_TWITTER,
    "text",
    true
  )?.[0];

  const companyFacebook = GetValueForKey(
    TEXTS_MAPPING.COMPANY_FACEBOOK,
    "text",
    true
  )?.[0];

  const companyInstagram = GetValueForKey(
    TEXTS_MAPPING.COMPANY_INSTAGRAM,
    "text",
    true
  )?.[0];

  const companyLinkedLinkedIn = GetValueForKey(
    TEXTS_MAPPING.COMPANY_LINKEDIN,
    "text",
    true
  )?.[0];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        objectFit: "cover",
        marginLeft: "-8px",
      }}
    >
      <Box
        sx={{
          background: "#FDC830",
          background: "-webkit-linear-gradient(to right, #F37335, #FDC830)",
          background: "linear-gradient(to right, #F37335, #FDC830)",
          color: "white",
          marginTop: "7.5vh",
          marginBottom: "10vh",
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50px",
          padding: "2rem 0rem",
          boxShadow: "0px 4px 8px 4px rgba(0,0,0,0.2)",
          fontSize: "1rem",
          "@media screen and (min-width: 768px)": {
            width: "60%",
            fontSize: "1.25rem",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontFamily: "Nunito Sans",
          }}
        >
          Contact Us At
        </Typography>
        <Box
          sx={{
            marginTop: "20px",
          }}
        >
          {companyMail && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                height: "auto",
                marginBottom: "1rem",
              }}
            >
              <MailOutlineIcon />
              <p style={{ color: "white", margin: 0 }}>{companyMail}</p>
            </Box>
          )}
          {companyPhone && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                height: "auto",
                marginBottom: "1rem",
              }}
            >
              <PhoneIcon />
              <p style={{ color: "white", margin: 0 }}>{companyPhone}</p>
            </Box>
          )}
          {companyAddress && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                height: "auto",
                marginBottom: "1rem",
              }}
            >
              <LocationOnIcon />
              <p style={{ color: "white", margin: 0 }}>{companyAddress}</p>
            </Box>
          )}
          {companyTwitter && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem", // Adjusted gap for responsiveness
                height: "auto", // Adjusted height for responsiveness
                marginBottom: "1rem", // Added marginBottom for responsiveness
              }}
            >
              <>
                <Twitter />
                <a
                  href={companyTwitter}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    gap: "1rem", // Adjusted gap for responsiveness
                  }}
                >
                  Contact Us on Twitter
                </a>
              </>
            </Box>
          )}
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}
