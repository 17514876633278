import React, { useState, useEffect } from "react";
import { TextField, Box, FormControlLabel, Checkbox } from "@mui/material";

const HomeLoanForm = ({ setFormData, setIsNextDisabled }) => {
  const [formData, setLocalFormData] = useState({
    currentHomeOwnership: "",
    currentHomeToBeSold: false,
    typeOfPurchase: "",
    prequalifiedForLoan: "",
    representedByRealtor: false,
  });

  const [invalidFields, setInvalidFields] = useState({
    currentHomeToBeSold: false,
    representedByRealtor: false,
  });

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData({
      ...formData,
      [name]:
        name === "currentHomeToBeSold" || name === "representedByRealtor"
          ? checked
          : value,
    });
    setLocalFormData({
      ...formData,
      [name]:
        name === "currentHomeToBeSold" || name === "representedByRealtor"
          ? checked
          : value,
    });
    validateField(name, value);
  };

  const regexMap = {
    currentHomeOwnership: /^[A-Za-z\s]+$/,
    typeOfPurchase: /^[A-Za-z\s]+$/,
    prequalifiedForLoan: /^[A-Za-z\s]+$/,
    currentHomeToBeSold: /^[A-Za-z\s]+$/,
    representedByRealtor: /^[A-Za-z\s]+$/,
  };
  const validateField = (fieldName, value) => {
    const isValid = regexMap[fieldName]?.test(value);
    setInvalidFields({ ...invalidFields, [fieldName]: !isValid });
  };

  useEffect(() => {
    const hasInvalidFields = Object.values(invalidFields).some(
      (invalid) => invalid
    );
    const areAllFieldsFilled =
      Object.keys(invalidFields).length === Object.keys(formData).length;
    setIsNextDisabled(hasInvalidFields || !areAllFieldsFilled);
  }, [invalidFields]);

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", py: 2 }}>
      <h2
        style={{
          marginBottom: "20px",
          fontFamily: "Poppins",
          fontWeight: "400",
        }}
      >
        Home Loan Details
      </h2>
      <TextField
        fullWidth
        label="Current Home Ownership"
        name="currentHomeOwnership"
        value={formData.currentHomeOwnership}
        onChange={handleChange}
        required
        error={invalidFields.currentHomeOwnership}
        helperText={
          invalidFields.currentHomeOwnership && "Please enter a valid value"
        }
        sx={{ marginBottom: "20px" }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.currentHomeToBeSold}
            onChange={handleChange}
            name="currentHomeToBeSold"
            color="primary"
          />
        }
        label="Current Home To Be Sold"
        sx={{ marginBottom: "20px" }}
      />
      <TextField
        fullWidth
        label="Type of Purchase"
        name="typeOfPurchase"
        value={formData.typeOfPurchase}
        onChange={handleChange}
        required
        error={invalidFields.typeOfPurchase}
        helperText={
          invalidFields.typeOfPurchase && "Please enter a valid value"
        }
        sx={{ marginBottom: "20px" }}
      />
      <TextField
        fullWidth
        label="Prequalified for Loan"
        name="prequalifiedForLoan"
        value={formData.prequalifiedForLoan}
        onChange={handleChange}
        required
        error={invalidFields.prequalifiedForLoan}
        helperText={
          invalidFields.prequalifiedForLoan && "Please enter a valid value"
        }
        sx={{ marginBottom: "20px" }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.representedByRealtor}
            onChange={handleChange}
            name="representedByRealtor"
            color="primary"
          />
        }
        label="Represented by Realtor"
        sx={{ marginBottom: "20px" }}
      />
    </Box>
  );
};

export default HomeLoanForm;
