import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import { GetValueForKey } from "../../utils/helper";
import { TEXTS_MAPPING } from "../../utils/textMapping";
import Footer from "../../components/Footer";
import { IMAGES_MAPPING } from "../../utils/imageMappings";
import Banner from "../../components/Banners/staticApplyNowBanners";
import Carousel from "../../components/Banners/carouselBanners";
import AboutUs from "../AboutUs";
import ApplyNowButton from "../../components/ApplyNowButton";
import WhatWeOffer from "../../components/WhatWeOffer";
import WhyChoose from "../../components/WhyChoose";
import hero_section_img from "../../assets/hero_section_img.jpg";
import PersonalLoanImage from "../../assets/Personal_loan.png";
import HomeLoanImage from "../../assets/Home_loan.png";
import BusinessLoanImage from "../../assets/Business_loan.png";
import { styled } from "@mui/material/styles";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "-10px",
    marginLeft: "-8px",
    width: "100vw",
    overflow: "hidden",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "200px", // Set a maximum height to prevent images from becoming too large
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap", // Wrap buttons to the next line if there's not enough space
  },
  buttonWrapper: {
    margin: theme.spacing(2),
  },
  label: {
    marginTop: theme.spacing(1),
  },
  heroImage: {
    maxWidth: "100%",
    height: "50vh",
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    objectFit: "cover",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: "Nunito Sans",
  },
  title2: {
    fontFamily: "Poppins",
    background: "#FDC830",
    background: "-webkit-linear-gradient(to right, #F37335, #FDC830)",
    background: "linear-gradient(to right, #F37335, #FDC830)",
    fontWeight: "600",
    borderRadius: "20px",
    color: "white",
  },
  subtitle: {
    marginBottom: theme.spacing(4),
    fontFamily: "Nunito Sans",
    fontWeight: "250",
  },
  introText: {
    marginBottom: theme.spacing(2),
  },
}));

export default function HomePage() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [loanType, setLoanType] = useState("");

  const handleClickOpen = (type) => {
    setLoanType(type);
    setOpenDialog(true);
  };

  const companyName = GetValueForKey(
    TEXTS_MAPPING.COMPANY_NAME,
    "text",
    true
  )?.[0];

  const homePageCarouselBanner = GetValueForKey(
    IMAGES_MAPPING.HOME_PAGE_BANNER_CAROUSEL,
    "image",
    false
  );

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === homePageCarouselBanner.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [homePageCarouselBanner.length]);
  const servicesCardsData = [
    {
      id: 1,
      logo: PersonalLoanImage,
      heading: "Personal Loan",
      text: "Realize your goals and ambitions with our flexible personal loan options, designed to fit your life.",
      handle: "Personal Loan",
    },
    {
      id: 2,
      logo: HomeLoanImage,
      heading: "Home Loan",

      text: "Discover your dream home with our simplified home loan process, tailored to your needs.",
      handle: "Home Loan",
    },
    {
      id: 3,
      logo: BusinessLoanImage,
      heading: "Business Loan",

      text: "Drive your business forward with our specialized loan solutions, crafted to fuel growth and innovation.",
      handle: "Business Loan",
    },
  ];
  const CustomButton = styled(Button)({
    color: "#F37335",
    borderColor: "#F37335",
    borderRadius: "12px",
    "&:hover": {
      background: "#FDC830",
      background: "-webkit-linear-gradient(to right, #F37335, #FDC830)",
      background: "linear-gradient(to right, #F37335, #FDC830)",
      color: "white",
      borderColor: "#F37335",
    },
  });
  const EndIconWrapper = styled(Box)({
    color: "#F37335",
  });
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        {/* <Carousel /> */}
        <br></br>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "40px", md: "130px" },
            margin: { xs: "0 0 50px 0", md: "30px" },
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "300px", md: "auto" },
            }}
          >
            <Typography variant="h4" gutterBottom className={classes.title}>
              Welcome to
              <Typography variant="h2" gutterBottom className={classes.title2}>
                {companyName ? companyName.toUpperCase() : "EasyLoans"}
              </Typography>
            </Typography>
            <Typography variant="h5" className={classes.subtitle}>
              Your One-Stop Destination for Hassle-Free Loans
            </Typography>
            <CustomButton
              variant="outlined"
              onClick={() => handleClickOpen("")}
              sx={{
                fontSize: "22px",
              }}
              endIcon={
                <EndIconWrapper>
                  <KeyboardDoubleArrowRightIcon
                    fontSize="medium"
                    sx={{ marginTop: "10px" }}
                  />
                </EndIconWrapper>
              }
            >
              Apply Now
            </CustomButton>
          </Box>

          <img
            style={{
              width: "500px",
              height: "auto",
            }}
            src={hero_section_img}
            alt=""
          />
        </Box>

        {/* <Banner /> */}
        <br></br>

        {/* Add images above buttons for different loan types */}
        <Box
          sx={{
            textAlign: "center",
            marginTop: "50px",
            fontFamily: "Nunito Sans",
            fontWeight: "400",
            fontSize: "2.125rem",
            letterSpacing: "0.00735em",
          }}
        >
          Our Services
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "70px",
            margin: "50px 0",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {servicesCardsData.map((item) => (
            <Box
              key={item.id}
              sx={{
                width: "250px",
                height: "310px",
                boxShadow: "0 4px 8px 4px rgba(0,0,0,0.2)",
                backgroundColor: "white",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px",
                borderRadius: "20px",
              }}
            >
              <Box
                sx={{
                  background: "#FDC830",
                  background:
                    "-webkit-linear-gradient(to right, #F37335, #FDC830)",
                  background: "linear-gradient(to right, #F37335, #FDC830)",
                  borderRadius: "70px",
                  padding: "10px",
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    width: "70px",
                    height: "auto",
                  }}
                  src={item.logo}
                  alt=""
                />
              </Box>
              <Box
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Nunito Sans",
                    fontWeight: "400",
                    fontSize: "20px",
                  }}
                >
                  {item.heading}
                </Box>
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "250",
                    fontSize: "15px",
                  }}
                >
                  {item.text}
                </Box>
              </Box>
              <CustomButton
                onClick={() => handleClickOpen(item.handle)}
                variant={"outlined"}
              >
                Apply Now
              </CustomButton>
            </Box>
          ))}
        </Box>
      </main>
      <ApplyNowButton
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        setLoanType={setLoanType}
        loanType={loanType}
      />
      {/* <AboutUs /> */}

      <WhyChoose />
      <WhatWeOffer />
      <Footer />
    </div>
  );
}
