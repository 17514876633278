import * as jwt_decode from "jwt-decode";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const GetValidatedTokenData = () => {
  const token = localStorage.getItem("Token");
  if (token) {
    const decodedJwt = parseJwt(token);
    if (decodedJwt.exp * 1000 > Date.now()) {
      return { ...jwt_decode(token), isLoggedIn: true };
    } else {
      localStorage.removeItem("Token");
      window.location.href = "/sign-in";
      return { isLoggedIn: false };
    }
  } else {
    // if (window.location.pathname !== "/sign-in")
    //   window.location.href = "/sign-in";
    // needs to be added back once we have defined routes which needs token or not
    return { isLoggedIn: false };
  }
};

export const GetValueForKey = (key, shouldLookIn, strict) => {
  const res = [];
  if (shouldLookIn === "image") {
    const imagesStr = localStorage.getItem("images");
    const images = JSON.parse(imagesStr) ?? [];
    images.forEach((data) => {
      if (strict) {
        if (data.name === key) {
          res.push(data.link);
        }
      } else {
        if (data.name.includes(key)) {
          res.push(data.link);
        }
      }
    });
    return res;
  } else if (shouldLookIn === "text") {
    const textsStr = localStorage.getItem("texts");
    const texts = JSON.parse(textsStr) ?? [];
    texts.forEach((data) => {
      if (strict) {
        if (data.name === key) {
          res.push(data.content);
        }
      } else {
        if (data.name.includes(key)) {
          res.push(data.content);
        }
      }
    });
    return res;
  }
};

export const getCurrentDomain = () => {
  const currentHostname = window.location.hostname;

 const hostnameParts = currentHostname.split('.');

  // Check if the hostname has enough parts to process
  if (hostnameParts.length >= 2) {
    // Check if the hostname has a subdomain
    if (hostnameParts.length > 2) {
      // If there is a subdomain, return it
      return hostnameParts.slice(0, hostnameParts.length - 2).join('.');
    } else {
      // No subdomain, just return the main domain
      return hostnameParts.slice(1).join('.');
    }
  }

  // Fallback to the full hostname if it cannot be parsed
  return currentHostname;
}


