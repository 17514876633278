import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useNavigate } from "react-router-dom";
import { IMAGES_MAPPING } from "../../utils/imageMappings";
import { GetValueForKey } from "../../utils/helper";
import { TEXTS_MAPPING } from "../../utils/textMapping";
import { Box, Button } from "@mui/material";
import logo from "../../assets/logo.jpg";
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Poppins",
    flexGrow: 1,
    position: "sticky",
    marginTop: "-10px",
    marginLeft: "-8px",
    top: 0,
    left: 0,
    right: 0,
    width: "100vw",
    zIndex: 99,
  },
  customAppBar: {
    backgroundColor: "white",
    padding: "15px",
  },
  menuButton: {
    padding: "0",
    margin: "0",
  },
  title: {
    flexGrow: 1,
  },
  logoContainer: {
    flexGrow: 1,
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  menuButton: {
    [theme.breakpoints.down("sm")]: {
      marginRight: 0, // Margin set to 0 for screens smaller than "sm"
    },
    background: "#FDC830",
    background: "-webkit-linear-gradient(to right, #F37335, #FDC830)",
    background: "linear-gradient(to right, #F37335, #FDC830)",
    marginLeft: "10px",
  },
}));

export default function TopNavBar() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    setAnchorEl(null);
    navigate("/sign-in");
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem("Token");
    navigate("/");
  };

  const companyLogoUrl = GetValueForKey(
    IMAGES_MAPPING.COMPANY_LOGO,
    "image",
    true
  )?.[0];

  const companyName = GetValueForKey(
    TEXTS_MAPPING.COMPANY_NAME,
    "text",
    true
  )?.[0];

  const handleAboutUsClick = () => {
    const aboutUsSection = document.getElementById("about-us");
    if (aboutUsSection) {
      aboutUsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleContactUsClick = () => {
    navigate("/contact-us");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.customAppBar}>
        <Toolbar className={classes.toolbar}>
          <div
            className={classes.logoContainer}
            style={{ display: "flex", alignItems: "center", gap: "20px" }}
          >
            {companyLogoUrl ? (
              <img
                src={companyLogoUrl}
                alt="Company Logo"
                style={{
                  cursor: "pointer",
                  maxWidth: "200px",
                  maxHeight: "60px",
                  width: "auto",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                }}
              />
            ) : (
              <img
                src={logo}
                alt="Logo"
                style={{
                  cursor: "pointer",
                  maxWidth: "200px",
                  maxHeight: "60px",
                  width: "auto",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                }}
              />
            )}
          </div>

          <Box
            sx={{
              display: { md: "flex", xs: "none" },
              gap: "20px",
              margin: "0 10px",
              color: "black",
              cursor: "pointer",
            }}
          >
            <Box onClick={handleAboutUsClick}>ABOUT US</Box>
            <Box onClick={handleContactUsClick}>CONTACT US</Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              edge="end"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{
              marginTop: "50px",
            }}
          >
            {localStorage.getItem("Token") ? (
              <MenuItem onClick={handleLogout}>LOGOUT</MenuItem>
            ) : (
              <MenuItem onClick={handleLogin}>LOGIN</MenuItem>
            )}
            <MenuItem onClick={handleAboutUsClick} hidden={{ md: true }}>
              ABOUT US
            </MenuItem>
            <MenuItem onClick={handleContactUsClick} hidden={{ md: true }}>
              CONTACT US
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}
