import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Button,
  Input,
  CircularProgress,
  Grid,
} from "@mui/material";
import handleFileUpload from "../../api/axios/fileUpload";
import axios from "axios";
import { handleAlert } from "../../utils/handleAlert";

const BusinessLoanForm = ({
  setFormData,
  applicationId,
  setIsNextDisabled,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [formData, setLocalFormData] = useState({
    lisenceType: "",
    lisenceImage: "",
    businessType: "",
    yearsOfBusiness: "",
  });
  const [invalidFields, setInvalidFields] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLocalFormData({ ...formData, [name]: value });
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const maxFileSize = 5 * 1024 * 1024;
    if (selectedFile.size > maxFileSize) {
      handleAlert("File size exceeds 5MB", "error");
    } else {
      const source = axios.CancelToken.source();
      setIsUploading(true);
      const url = await handleFileUpload(
        event.target.files[0],
        source.token,
        `${applicationId}_lisenceImage`
      );
      setIsUploading(false);
      setFormData({ ...formData, lisenceImage: url });
      setLocalFormData({ ...formData, lisenceImage: url });
      validateField("lisenceImage", url);
    }
  };

  const regexMap = {
    lisenceType: /^[A-Za-z0-9\s,-]+$/,
    businessType: /^[A-Za-z0-9\s,-]+$/,
    yearsOfBusiness: /^\d+$/,
    lisenceImage: /.*/,
  };
  const validateField = (fieldName, value) => {
    const isValid = regexMap[fieldName]?.test(value);
    setInvalidFields({ ...invalidFields, [fieldName]: !isValid });
  };

  useEffect(() => {
    const hasInvalidFields = Object.values(invalidFields).some(
      (invalid) => invalid
    );
    const areAllFieldsFilled =
      Object.keys(invalidFields).length === Object.keys(formData).length;
    setIsNextDisabled(hasInvalidFields || !areAllFieldsFilled);
  }, [invalidFields]);

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", py: 2 }}>
      <h2
        style={{
          marginBottom: "20px",
          fontFamily: "Poppins",
          fontWeight: "400",
        }}
      >
        Business Details
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}></Grid>
        <TextField
          fullWidth
          label="License Type"
          name="lisenceType"
          value={formData.lisenceType}
          onChange={handleChange}
          required
          error={invalidFields.lisenceType}
          helperText={
            invalidFields.lisenceType && "Please enter a license type"
          }
          sx={{ marginBottom: "20px" }}
        />
        <Input
          fullWidth
          type="file"
          accept=".jpg,.jpeg,.png,.pdf"
          onChange={handleFileChange}
          disableUnderline // Remove default underline
          sx={{ display: "none" }} // Hide the default input
          id="lisenceImageInput" // Associate with custom button
        />
        <label htmlFor="lisenceImageInput">
          <Button
            variant="outlined"
            component="span"
            sx={{
              marginBottom: "20px",
              color: "#F37335",
              borderColor: "#F37335",
            }}
          >
            Upload License Image
          </Button>
        </label>
        <div style={{ color: "red" }}>{"File Size should not excced 5MB"}</div>
        {isUploading && ( // Conditionally render loader while uploading
          <Box display="flex" justifyContent="center" marginBottom="20px">
            <CircularProgress />
          </Box>
        )}
        <TextField
          fullWidth
          label="Business Type"
          name="businessType"
          value={formData.businessType}
          onChange={handleChange}
          required
          error={invalidFields.businessType}
          helperText={
            invalidFields.businessType && "Please enter a business type"
          }
          sx={{ marginBottom: "20px" }}
        />
        <TextField
          fullWidth
          label="Years of Business"
          name="yearsOfBusiness"
          value={formData.yearsOfBusiness}
          onChange={handleChange}
          required
          error={invalidFields.yearsOfBusiness}
          helperText={
            invalidFields.yearsOfBusiness && "Please enter years of business"
          }
          sx={{ marginBottom: "20px" }}
        />
      </Grid>
    </Box>
  );
};

export default BusinessLoanForm;
