import axios from "axios";
import { handleAlert } from "../../utils/handleAlert";
import { getCurrentDomain } from '../../utils/helper'

const PerformRequest = (url, method, body, params) => {
  const Token = localStorage.getItem("Token");
  const domain = getCurrentDomain()

  const api = axios.create({
    baseURL: `${process.env.REACT_APP_URL}/`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Cross-Origin-Opener-Policy": "same-origin-allow-popups",
      Authorization: `bearer ${Token}`,
      "domain": domain
    },
  });

  return new Promise((resolve, reject) => {
    api
      .request({ method: method, url: url, data: body, params: params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        handleAlert(error.response.data.reason, "error");
      });
  });
};

export default PerformRequest;
