import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { request } from "../../api/requests";
import { handleAlert } from "../../utils/handleAlert";
import UpdateDetailsPopup from "../../components/UpdateAppDataPopUp";
import * as XLSX from "xlsx";
import RemarkPopup from "../../components/RemarkPopup";

const ApplicationsPage = () => {
  const { userId } = useParams();
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  const [openRemarkPopup, setOpenRemarkPopup] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [selectedApplicationId, setSelectedApplicationId] = useState(null);

  const handleOpenRemarkPopup = (remark, applicationId) => {
    setSelectedRemark(remark);
    setSelectedApplicationId(applicationId);
    setOpenRemarkPopup(true);
  };

  const handleCloseRemarkPopup = () => {
    setSelectedRemark(null);
    setOpenRemarkPopup(false);
    fetchApplications();
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const fetchApplications = async () => {
    try {
      const { results, totalPages } = await request.getAllApplications(
        pageNo,
        search,
        status
      );
      setApplications(results);
      setTotalPages(totalPages);
      setLoading(false);
    } catch (error) {
      handleAlert("Error fetching applications:", "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, [userId, pageNo, search, status, loading]);

  const handleOpenModal = (application) => {
    setSelectedApplication(application);
  };

  const handleCloseModal = () => {
    setSelectedApplication(null);
  };

  const handlePageChange = (newPageNo) => {
    setPageNo(newPageNo);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleStatusFilter = (selectedStatus) => {
    setStatus(selectedStatus);
  };

  const handleStatusUpdate = async (applicationId, newStatus) => {
    try {
      await request.updateApplicationStatus(applicationId, newStatus);
      await fetchApplications();
    } catch (error) {
      handleAlert("Error updating status:", "error");
    }
  };

  const handleCopyData = () => {
    if (selectedApplication) {
      const copyText = JSON.stringify(selectedApplication);
      navigator.clipboard.writeText(copyText);
      handleAlert("Data copied to clipboard", "success");
    }
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(applications);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Applications");
    XLSX.writeFile(workbook, "applications.xlsx");
  };

  const isFile = (value) => {
    if (typeof value !== "string") {
      return false;
    }
    const fileExtension = value.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
    const documentExtensions = ["pdf", "doc", "docx", "txt"];
    if (
      imageExtensions.includes(fileExtension) ||
      documentExtensions.includes(fileExtension)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Applications for User: {userId}
        </Typography>
        <div
          style={{
            fontSize: "25px",
            fontFamily: "Nunito Sans, sans-serif",
            fontWeight: "400",
            marginBottom: "20px",
          }}
        >
          Premium signup users will get 1.5 to 2.5 percentage on all banks.
          Commission will be added to NLOS wallets
        </div>
        <div>
          <Button
            onClick={handleOpenPopup}
            style={{
              border: "1px solid black",
            }}
          >
            Update Company Details & Images
          </Button>
          <UpdateDetailsPopup open={openPopup} onClose={handleClosePopup} />
        </div>
        <Box mb={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" gutterBottom>
                Search:
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={search}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" gutterBottom>
                Status Filter:
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                value={status}
                onChange={(e) => handleStatusFilter(e.target.value)}
              >
                <MenuItem value="">All Statuses</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
        <Button
          variant="outlined"
          onClick={handleExportToExcel}
          style={{ display: "flex", marginLeft: "auto" }}
        >
          Export to Excel
        </Button>
        {loading ? (
          <Box textAlign="center" style={{ marginTop: "20px" }}>
            <Typography variant="h6">Loading...</Typography>
          </Box>
        ) : (
          <Box mb={2} style={{ overflowX: "auto", margin: "20px 0" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                      width: "20%",
                    }}
                  >
                    Full Name
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                      width: "20%",
                    }}
                  >
                    Email
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    Phone Number
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    Loan Type
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    Details
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                      width: "15%",
                    }}
                  >
                    Remarks
                  </th>
                </tr>
              </thead>
              <tbody>
                {applications.map((application) => (
                  <tr key={application.id}>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {application.fullName}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {application.email}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {application.phoneNumber}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {application.loanType}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Select
                        fullWidth
                        variant="outlined"
                        value={application.status}
                        onChange={(e) =>
                          handleStatusUpdate(application.id, e.target.value)
                        }
                      >
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Approved">Approved</MenuItem>
                        <MenuItem value="Rejected">Rejected</MenuItem>
                      </Select>
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => handleOpenModal(application)}
                      >
                        View
                      </Button>
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() =>
                          handleOpenRemarkPopup(
                            application.remarks,
                            application.id
                          )
                        }
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        )}
        <Box
          mt={2}
          textAlign="center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => handlePageChange(pageNo - 1)}
            disabled={pageNo === 1}
            style={{ marginRight: "10px" }}
          >
            Previous
          </Button>
          <span>{pageNo}</span>
          <Button
            variant="outlined"
            onClick={() => handlePageChange(pageNo + 1)}
            disabled={pageNo === totalPages}
            style={{ marginLeft: "10px" }}
          >
            Next
          </Button>
        </Box>
        <Modal
          open={selectedApplication !== null}
          onClose={handleCloseModal}
          aria-labelledby="application-details-modal"
          aria-describedby="application-details-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              border: "2px solid #000",
              borderRadius: "20px",
              padding: "20px",
              width: "80%",
              maxHeight: "80%",
              overflowY: "scroll", // Changed overflow to scroll
              outline: "none",
              position: "relative",
            }}
          >
            <Typography
              variant="h6"
              id="application-details-modal"
              gutterBottom
            >
              Application Details
            </Typography>
            {selectedApplication && (
              <div>
                {Object.entries(selectedApplication).map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: "8px",
                      borderBottom: "1px solid #ccc",
                      paddingBottom: "8px",
                    }}
                  >
                    <div style={{ flex: 1, fontWeight: "bold" }}>{key}</div>
                    {typeof value === "string" && (
                      <div style={{ flex: 1 }}>
                        {isFile(value) ? (
                          <a
                            href={`${value}`}
                            download={`${
                              selectedApplication.fullName
                            }_${key}.${value.split(".").pop().toLowerCase()}`}
                          >
                            Download File
                          </a>
                        ) : (
                          value
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            <Button
              onClick={handleCloseModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "red",
                color: "#fff",
                borderRadius: "8px",
                padding: "8px 16px",
              }}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              onClick={handleCopyData}
              style={{
                position: "absolute",
                top: "10px",
                right: "100px",
                backgroundColor: "#3f51b5",
                color: "#fff",
                borderRadius: "8px",
                padding: "8px 16px",
              }}
            >
              Copy Data
            </Button>
          </div>
        </Modal>
        <RemarkPopup
          open={openRemarkPopup}
          onClose={handleCloseRemarkPopup}
          initialRemark={selectedRemark}
          applicationId={selectedApplicationId}
        />
      </div>
    </>
  );
};

export default ApplicationsPage;
