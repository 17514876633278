import React from "react";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SavingsIcon from "@mui/icons-material/Savings";
import { Typography, Box } from "@mui/material";
import rupee_sign from "../../assets/rupee-sign.png";

export default function WhatWeOffer() {
  const data1 = [
    {
      id: 1,
      logo: <SavingsIcon />,
      heading: "Personal Loan for Self-Employed",
      text: "Personal loans for business owners to fulfill credit needs of ₹10,000 to ₹2 Lakhs with repayment tenures ranging between 4 to 24 months.",
    },
    {
      id: 2,
      logo: <AccountBalanceWalletIcon />,
      heading: "Flexi Personal Loan",
      text: "Instant personal loans for urgent credit between ₹1,000 to ₹80,000 with flexible tenures between 3 to 10 months.",
    },
  ];
  const data2 = [
    {
      id: 1,
      logo: <CurrencyRupeeIcon />,
      heading: "Personal Loan for Salaried",
      text: "Quick personal loans for salaried individuals with repayment terms between 4 to 36 months for a loan amount upto 17 Lakhs from one bank.",
    },
    {
      id: 2,
      logo: <ApartmentIcon />,
      heading: "Business Loan",
      text: "Flexible business loans specially tailored to meet your entrepreneurial needs. Access funds for various purposes, such as expansion, operations, or investment, with competitive interest rates.",
    },
  ];

  return (
    <div
      style={{
        padding: "0 35px",
        marginBottom: "20px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          marginBottom: "50px",
          fontFamily: "Nunito Sans",
        }}
      >
        What We Offer?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Box>
            {data1.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginBottom: "1rem",
                  padding: "20px 0",
                }}
              >
                <div
                  style={{
                    paddingTop: "5px",
                  }}
                >
                  {item.logo}
                </div>
                <div style={{ marginLeft: "1rem" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Nunito Sans",
                    }}
                  >
                    {item.heading}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "200",
                    }}
                  >
                    {item.text}
                  </Typography>
                </div>
              </Box>
            ))}
          </Box>

          <img
            style={{
              width: "40%",
              height: "auto",
              padding: "0 30px",
            }}
            src={rupee_sign}
            alt="Rupee Sign"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {data2.map((item) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: "1rem",
                padding: "20px 0",
              }}
            >
              <Box
                sx={{
                  paddingTop: "5px",
                }}
              >
                {item.logo}
              </Box>
              <Box style={{ marginLeft: "1rem" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Nunito Sans",
                  }}
                >
                  {item.heading}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "200",
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
}
