import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Button,
  Input,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import handleFileUpload from "../../api/axios/fileUpload";
import axios from "axios";
import { handleAlert } from "../../utils/handleAlert";

const AddressProofInfoForm = ({
  setFormData,
  applicationId,
  setIsNextDisabled,
}) => {
  const [formData, setLocalFormData] = useState({
    currentAddress: "",
    residentialAddress: "",
    AddressProofImage: "",
    loanAmount: "",
    anyPreviousLoan: false,
    interestOnPreviousLoan: 0,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [invalidFields, setInvalidFields] = useState({
    anyPreviousLoan: false,
    interestOnPreviousLoan: false,
  });
  const [showInterestField, setShowInterestField] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(""); // State to store the uploaded file name

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "anyPreviousLoan") {
      setFormData({ ...formData, [name]: checked });
      setLocalFormData({ ...formData, [name]: checked });
      setShowInterestField(checked);
      validateField(name, value);
    } else {
      setFormData({ ...formData, [name]: value });
      setLocalFormData({ ...formData, [name]: value });
      validateField(name, value);
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const maxFileSize = 5 * 1024 * 1024;
    if (selectedFile.size > maxFileSize) {
      handleAlert("File size exceeds 5MB", "error");
    } else {
      const source = axios.CancelToken.source();
      setIsUploading(true);
      const url = await handleFileUpload(
        event.target.files[0],
        source.token,
        `${applicationId}_AddressProofImage`
      );
      setIsUploading(false);
      setFormData({ ...formData, AddressProofImage: url });
      setLocalFormData({ ...formData, AddressProofImage: url });
      validateField("AddressProofImage", url);
      setUploadedFileName(selectedFile.name);
    }
  };

  const regexMap = {
    currentAddress: /^[A-Za-z0-9\s,-]+$/,
    residentialAddress: /^[A-Za-z0-9\s,-]+$/,
    loanAmount: /^\d+(\.\d{1,2})?$/,
    AddressProofImage: /.*/,
    anyPreviousLoan: /.*/,
    interestOnPreviousLoan: /^\d+(\.\d{1,2})?$/,
  };
  const validateField = (fieldName, value) => {
    const isValid = regexMap[fieldName]?.test(value);
    setInvalidFields({ ...invalidFields, [fieldName]: !isValid });
  };

  useEffect(() => {
    const hasInvalidFields = Object.values(invalidFields).some(
      (invalid) => invalid
    );
    const areAllFieldsFilled =
      Object.keys(invalidFields).length === Object.keys(formData).length;
    setIsNextDisabled(hasInvalidFields || !areAllFieldsFilled);
  }, [invalidFields]);

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", py: 2 }}>
      <h2
        style={{
          marginBottom: "20px",
          fontFamily: "Poppins",
          fontWeight: "400",
        }}
      >
        Address & Loan Details
      </h2>
      <TextField
        fullWidth
        label="Current Address"
        name="currentAddress"
        value={formData.currentAddress}
        onChange={handleChange}
        required
        error={invalidFields.currentAddress}
        helperText={
          invalidFields.currentAddress && "Please enter a valid current address"
        }
        sx={{ marginBottom: "20px" }}
      />
      <TextField
        fullWidth
        label="Residential Address"
        name="residentialAddress"
        value={formData.residentialAddress}
        onChange={handleChange}
        required
        error={invalidFields.residentialAddress}
        helperText={
          invalidFields.residentialAddress &&
          "Please enter a valid residential address"
        }
        sx={{ marginBottom: "20px" }}
      />
      <Input
        fullWidth
        type="file"
        accept=".jpg,.jpeg,.png,.pdf"
        onChange={handleFileChange}
        disableUnderline // Remove default underline
        sx={{ display: "none" }} // Hide the default input
        id="addressProofImageInput" // Associate with custom button
      />
      <label htmlFor="addressProofImageInput">
        <Button
          variant="outlined"
          component="span"
          sx={{
            marginBottom: "20px",
            color: "#F37335",
            borderColor: "#F37335",
          }}
        >
          Upload Address Proof Image
        </Button>
      </label>
      <div style={{ color: "red" }}>{"File Size should not excced 5MB"}</div>
      {uploadedFileName && ( // Display the uploaded file name if available
        <p>Uploaded file: {uploadedFileName}</p>
      )}
      {isUploading && ( // Conditionally render loader while uploading
        <Box display="flex" justifyContent="center" marginBottom="20px">
          <CircularProgress />
        </Box>
      )}
      <TextField
        fullWidth
        label="Loan Amount"
        name="loanAmount"
        value={formData.loanAmount}
        onChange={handleChange}
        required
        error={invalidFields.loanAmount}
        helperText={
          invalidFields.loanAmount && "Please enter a valid loan amount"
        }
        sx={{ marginBottom: "20px" }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.anyPreviousLoan}
            onChange={handleChange}
            name="anyPreviousLoan"
            color="primary"
          />
        }
        label="Any Previous Loan"
        sx={{ marginBottom: "20px" }}
      />
      {showInterestField && (
        <TextField
          fullWidth
          label="Interest on Previous Loan"
          name="interestOnPreviousLoan"
          value={formData.interestOnPreviousLoan}
          onChange={handleChange}
          type="number"
          required
          error={invalidFields.interestOnPreviousLoan}
          helperText={
            invalidFields.interestOnPreviousLoan &&
            "Please enter a valid interest amount"
          }
          sx={{ marginBottom: "20px" }}
        />
      )}
    </Box>
  );
};

export default AddressProofInfoForm;
