import React from "react";
import { GetValueForKey } from "../../utils/helper";
import { Typography, Box } from "@mui/material";
import { TEXTS_MAPPING } from "../../utils/textMapping";
import PrintDisabledIcon from "@mui/icons-material/PrintDisabled";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import SpeedIcon from "@mui/icons-material/Speed";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LockResetIcon from "@mui/icons-material/LockReset";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";

export default function WhyChoose() {
  const companyName = GetValueForKey(
    TEXTS_MAPPING.COMPANY_NAME,
    "text",
    true
  )?.[0];
  const data = [
    {
      id: 1,
      logo: <TrendingDownIcon />,
      heading: "Lower Interest Rates",
      text: "Get loans for multiple purposes at lower interest rates to suit your needs",
    },
    {
      id: 2,
      logo: <SpeedIcon />,
      heading: "Fast Processing and Disbursal",
      text: "Apply online, check your eligibility and get money directly in your bank in 10 minutes",
    },
    {
      id: 3,
      logo: <CurrencyRupeeIcon />,
      heading: "Easy Repayment Options",
      text: "Repay the loan amount in easy EMI with flexible tenure options",
    },
    {
      id: 4,
      logo: <PrintDisabledIcon />,
      heading: "100% Paperless",
      text: "No paperwork or physical documentation is required, and you can apply and get a personal loan completely online.",
    },
    {
      id: 5,
      logo: <LockResetIcon />,
      heading: "Safe, Secure and Transparent",
      text: "Our loan application process is fully secured and safe and there are no hidden charges.",
    },
    {
      id: 6,
      logo: <NoMeetingRoomIcon />,
      heading: "Collateral Free",
      text: "No collateral is required to apply for our personal loans.",
    },
  ];
  const countData = [
    {
      id: 1,
      value: "4 Lakhs",
      type: "Loans Disbursed",
    },
    {
      id: 2,
      value: "1.2 Lakhs",
      type: "Loan Customers",
    },
    {
      id: 3,
      value: "7 Lakhs",
      type: "Active Users",
    },
    {
      id: 4,
      value: "60 Lakhs",
      type: "Max. Loan Amount",
    },
  ];
  return (
    <div style={{ position: "relative" }}>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          margin: "50px 0",
          fontFamily: "Nunito Sans",
        }}
      >
        Why Choose{" "}
        <span
          style={{
            backgroundImage:
              "-webkit-linear-gradient(to right, #F37335, #FDC830)",
            backgroundImage: "linear-gradient(to right, #F37335, #FDC830)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "transparent",
            fontWeight: "600",
          }}
        >
          {companyName ? companyName.toUpperCase() : "EasyLoans"}
        </span>{" "}
        ?
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: { xs: "20px", md: "50px" },
          padding: { xs: "0 10% 650px 10%", md: "0 10% 200px 10%" },
          zIndex: 2,
          position: "relative",
        }}
      >
        {data.map((item) => (
          <Box
            key={item.id}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "1rem",
              padding: "20px 0",
              flexDirection: "column",
              textAlign: "left",
              boxShadow: "0 4px 8px 4px rgba(0,0,0,0.2)",
              padding: "20px",
              borderRadius: "15px",
              width: "300px",
              backgroundColor: " white",
            }}
          >
            <Box
              sx={{
                paddingTop: "5px",
              }}
            >
              {item.logo}
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Nunito Sans",
                paddingBottom: "10px",
              }}
            >
              {item.heading}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "200",
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          background: "#FDC830",
          background: "-webkit-linear-gradient(to right, #F37335, #FDC830)",
          background: "linear-gradient(to right, #F37335, #FDC830)",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          position: "absolute",
          width: "80%",
          bottom: { xs: "100px", md: "60px" },
          alignItems: "center",
          textAlign: { xs: "center", md: "left" },
          gap: "30px",
          left: 0,
          zIndex: 1,
          padding: "150px 10% 30px 10%",
        }}
      >
        {countData.map((item) => (
          <Box key={item.id}>
            <Typography
              sx={{
                color: "white",
                fontWeight: "250",
                fontFamily: "Poppins",
                fontSize: "15px",
              }}
            >
              Over
            </Typography>
            <Typography
              sx={{
                color: "White",
                fontFamily: "Nunito Sans",
                fontSize: "30px",
                fontWeight: "600",
              }}
            >
              {item.value}
            </Typography>
            <Typography
              sx={{
                // color: "#A5A9C8",
                color: "white",
                fontWeight: "250",
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              {item.type}
            </Typography>
          </Box>
        ))}
      </Box>
    </div>
  );
}
