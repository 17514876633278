import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import image from "../../assets/willgetbacktoyou.jpg"; // Import your related image
import { useNavigate } from "react-router-dom";

const MessagePopup = ({ open, handleClose }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          p: 4,
          borderRadius: 5,
          width: "50%", // Set width to half screen size
          transform: "translate(-50%, -50%)", // Center horizontally and vertically
          maxHeight: "fit-content", // Allow content to dictate height
          display: "flex", // Use flexbox for vertical layout
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          top: "50%", // Set top position to 50%
          left: "50%", // Set left position to 50%
        }}
      >
        <CloseIcon
          sx={{ position: "absolute", top: 10, right: 10, cursor: "pointer" }}
          onClick={() => {
            handleClose();
            navigate("/");
          }}
        />
        <img
          src={image}
          alt="Related"
          style={{
            maxWidth: "200px", // Adjust image width as needed
            height: "auto",
            borderRadius: "10px",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        />
        <Typography
          variant="h5"
          style={{
            color: "#212529",
            marginBottom: "20px",
            fontFamily: "Poppins",
            textAlign: "center",
            margin: "20px 10px",
            fontWeight: "400",
          }}
        >
          We will get back to you shortly. One of our representatives will
          contact you.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{
            background: "#FDC830",
            background: "-webkit-linear-gradient(to right, #F37335, #FDC830)",
            background: "linear-gradient(to right, #F37335, #FDC830)",
            fontFamily: "Poppins",
            fontSize: "20px",
          }}
          component={Link}
          to="/"
        >
          Home
        </Button>
      </Box>
    </Modal>
  );
};

export default MessagePopup;
