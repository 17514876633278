import performRequest from "../axios";

export const request = {
  signIn: async (body) => {
    return await performRequest(`signin`, "POST", body);
  },
  addImage: async (name, link) => {
    return await performRequest(`images`, "POST", { name, link });
  },
  getImages: async () => {
    return await performRequest(`images`, "GET");
  },
  addText: async (name, content) => {
    return await performRequest(`texts`, "POST", { name, content });
  },
  getTexts: async (body) => {
    return await performRequest(`texts`, "GET");
  },
  createApplication: async (body) => {
    return await performRequest(`application`, "POST", body);
  },
  getAllApplications: async (pageNo, search, status) => {
    let query = "";
    if (search) {
      query = `search=${search}`;
    }
    if (status) {
      query = query + `&status=${status}`;
    }
    return await performRequest(`application/${pageNo}?${query}`, "GET");
  },
  updateApplication: async (id, body) => {
    return await performRequest(`application/${id}`, "PATCH", body);
  },
  getApplicationById: async (id) => {
    return await performRequest(`application/${id}`, "GET");
  },
  updateApplicationStatus: async (id, status) => {
    return await performRequest(`application/status/${id}`, "PATCH", {
      status,
    });
  },
  updateApplicationRemark: async (id, remarks) => {
    return await performRequest(`application/status/${id}`, "PATCH", {
      remarks,
    });
  },
  deleteApplication: async (id) => {
    return await performRequest(`application/${id}`, "DELETE");
  },
};
