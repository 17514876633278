import { GetValueForKey } from "../../utils/helper";
import { IMAGES_MAPPING } from "../../utils/imageMappings";
import { TEXTS_MAPPING } from "../../utils/textMapping";
import { Box } from "@mui/material";
import { Twitter, LinkedIn } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.jpg";

export default function Footer() {
  const navigate = useNavigate();

  const handleAboutUsClick = () => {
    const aboutUsSection = document.getElementById("about-us");
    if (aboutUsSection) {
      aboutUsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleContactUsClick = () => {
    navigate("/contact-us");
  };
  const companyLogoUrl = GetValueForKey(
    IMAGES_MAPPING.COMPANY_LOGO,
    "image",
    true
  )?.[0];

  const companyName = GetValueForKey(
    TEXTS_MAPPING.COMPANY_NAME,
    "text",
    true
  )?.[0];

  const companyOwnedByText = GetValueForKey(
    TEXTS_MAPPING.OWNED_BY_TEXT_FOOTER,
    "text",
    true
  )?.[0];

  const companyCopyrightLine = GetValueForKey(
    TEXTS_MAPPING.COMPANY_COPYRIGHT_LINE,
    "text",
    true
  )?.[0];

  const companyAddress = GetValueForKey(
    TEXTS_MAPPING.COMPANY_ADDRESS,
    "text",
    true
  )?.[0];

  const companyPhone = GetValueForKey(
    TEXTS_MAPPING.COMPANY_PHONE,
    "text",
    true
  )?.[0];

  const companyLinkedIn = GetValueForKey(
    TEXTS_MAPPING.COMPANY_LINKEDIN,
    "text",
    true
  )?.[0];

  const companyTwitter = GetValueForKey(
    TEXTS_MAPPING.COMPANY_TWITTER,
    "text",
    true
  )?.[0];

  return (
    <div
      className="footer"
      style={{
        width: "100vw",
        background: "#FDC830",
        background: "-webkit-linear-gradient(to right, #F37335, #FDC830)",
        background: "linear-gradient(to right, #F37335, #FDC830)",
        overflowX: "hidden",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        paddingX={{ xs: "5%", md: "10%", lg: "15%" }}
        paddingY={"20px"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {companyLogoUrl ? (
            <img
              src={companyLogoUrl && companyLogoUrl}
              alt="Company Logo"
              style={{
                maxWidth: "200px",
                maxHeight: "50px",
              }}
            />
          ) : (
            <img
              src={logo}
              alt="Company Logo"
              style={{
                maxWidth: "200px",
                maxHeight: "50px",
              }}
            />
          )}
        </Box>
        <Box>
          <p
            style={{
              color: "white",
              fontFamily: "Nunito Sans",
              fontWeight: "600",
            }}
          >
            INFORMATION
          </p>
          <p
            style={{
              color: "white",
              fontFamily: "Poppins",
              color: "white",
              fontWeight: "250",
            }}
          >
            {companyAddress ? companyAddress : TEXTS_MAPPING.COMPANY_ADDRESS}
          </p>
          <p
            style={{
              color: "white",
              fontFamily: "Poppins",
              color: "white",
              fontWeight: "250",
            }}
          >
            {companyPhone ? companyPhone : TEXTS_MAPPING.COMPANY_PHONE}
          </p>
        </Box>
        <Box>
          <p
            style={{
              color: "white",
              fontFamily: "Nunito Sans",
              fontWeight: "600",
            }}
          >
            HELPFUL LINKS
          </p>
          <p
            style={{
              color: "white",
              fontFamily: "Poppins",
              color: "white",
              cursor: "pointer",
              fontWeight: "250",
            }}
            onClick={handleAboutUsClick}
          >
            About Us
          </p>
          <p
            style={{
              color: "white",
              fontFamily: "Poppins",
              color: "white",
              cursor: "pointer",
              fontWeight: "250",
            }}
            onClick={handleContactUsClick}
          >
            Contact Us
          </p>
        </Box>
        <Box>
          <p
            style={{
              color: "white",
              fontFamily: "Nunito Sans",
              fontWeight: "600",
            }}
          >
            CONECT WITH US
          </p>
          <Box>
            {companyTwitter && (
              <a
                href={companyTwitter}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                <Twitter />
              </a>
            )}
            {companyLinkedIn && (
              <a
                href={companyLinkedIn}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "white",
                  textDecoration: "none",
                  padding: "0 10px",
                }}
              >
                <LinkedIn />
              </a>
            )}
          </Box>
        </Box>
      </Box>
      <hr
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "1px",
          margin: "0 auto",
          border: "none",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          color: "white",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem",
          fontFamily: "Poppins",
        }}
      >
        <p
          style={{
            textAlign: "center",
            marginBottom: "0",
          }}
        >
          Owned By{" "}
          {companyOwnedByText
            ? companyOwnedByText.toUpperCase()
            : TEXTS_MAPPING.OWNED_BY_TEXT_FOOTER}
        </p>
        <p
          style={{
            textAlign: "center",
          }}
        >
          ©️{" "}
          {companyName ? companyName.toUpperCase() : TEXTS_MAPPING.COMPANY_NAME}
          . Copyright.{" "}
          {companyCopyrightLine
            ? companyCopyrightLine.toUpperCase()
            : TEXTS_MAPPING.COMPANY_COPYRIGHT_LINE}
          .
        </p>
      </Box>
    </div>
  );
}
