export const TEXTS_MAPPING = {
    COMPANY_NAME: 'company name',
    COMPANY_PHONE: 'company phone',
    COMPANY_EMAIL: 'company email',
    TAG_LINE: 'tag line',
    ABOUT_US: 'about us',
    COMPANY_ADDRESS: 'company address',
    COMPANY_COPYRIGHT_LINE: 'company copy right line',
    COMPANY_BLOG: 'company blog',
    OWNED_BY_TEXT_FOOTER: 'owned by text footer',
    COMPANY_LINKEDIN: 'company linkedin',
    COMPANY_TWITTER: 'company twitter',
    COMPANY_FACEBOOK: 'company facebook',
    COMPANY_INSTAGRAM: 'company instagram',
  };
  