import React, { useState } from "react";
import { handleAlert } from "../../utils/handleAlert";
import { request } from "../../api/requests";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Padding } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "orange",
    },
  },
  button: {
    backgroundColor: "orange",
    color: "white",
    "&:hover": {
      backgroundColor: "darkorange",
    },
  },
  input: {
    "& .MuiInputBase-root.Mui-focused": {
      color: "orange",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "orange",
    },
  },
}));

export default function ApplyNowButton({
  setOpenDialog,
  openDialog,
  setLoanType,
  loanType,
}) {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState("");

  const navigate = useNavigate();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSubmit = () => {
    if (!phoneNumber) {
      handleAlert("Phone number is needed to proceed with the application");
      return;
    }
    let loanTypeRequest = "PERSONAL";
    if (loanType === "Personal Loan") {
      loanTypeRequest = "PERSONAL";
    } else if (loanType === "Home Loan") {
      loanTypeRequest = "HOME";
    } else if (loanType === "Business Loan") {
      loanTypeRequest = "BUSINESS";
    }
    request
      .createApplication({ phoneNumber, loanType: loanTypeRequest })
      .then((res) => {
        navigate(
          `/application/${res.applicationId}?loanType=${loanTypeRequest}`
        );
      });
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>Enter your phone number</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="phone"
            label="Phone Number"
            type="text"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            InputProps={{ className: classes.input }}
          />
          <TextField
            id="loan-type"
            select
            label="Loan Type"
            fullWidth
            value={loanType}
            onChange={(e) => setLoanType(e.target.value)}
            margin="normal"
          >
            <MenuItem value="Personal Loan">Personal Loan</MenuItem>
            <MenuItem value="Home Loan">Home Loan</MenuItem>
            <MenuItem value="Business Loan">Business Loan</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            className={classes.button}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
