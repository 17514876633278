import axios from 'axios';
import { getCurrentDomain } from '../../utils/helper'

const handleFileUpload = async (file, cancelToken, applicationId) => {
  try {
    const domain = getCurrentDomain();
    const extension = file?.name.split('.').pop();
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/upload/${extension}?id=${applicationId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
          'domain': domain
        },
        cancelToken: cancelToken,
      }
    );
    return response?.data?.publicUrl;
  } catch (error) {
    console.log(error);
    if (axios.isCancel(error)) {
      return null;
    }
    return null;
  }
};

export default handleFileUpload;
