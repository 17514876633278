import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { request } from "../../api/requests";
import { handleAlert } from "../../utils/handleAlert";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, md: 400 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RemarkPopup = ({ open, onClose, initialRemark, applicationId }) => {
  const [editedRemark, setEditedRemark] = useState(initialRemark);

  useEffect(() => {
    setEditedRemark(initialRemark);
  }, [initialRemark, open]);

  const handleRemarkChange = (event) => {
    setEditedRemark(event.target.value);
  };

  const handleSave = async () => {
    try {
      await request.updateApplicationRemark(applicationId, editedRemark);
      handleAlert("Remark updated successfully", "success");
      onClose();
    } catch (error) {
      handleAlert("Error updating remark", "error");
    }
  };

  const CustomButton = styled(Button)({
    color: "#F37335",
    borderColor: "#F37335",
    borderRadius: "12px",
    "&:hover": {
      background: "#FDC830",
      background: "-webkit-linear-gradient(to right, #F37335, #FDC830)",
      background: "linear-gradient(to right, #F37335, #FDC830)",
      color: "white",
      borderColor: "#F37335",
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="remark-popup-title"
      aria-describedby="remark-popup-description"
    >
      <Box sx={style}>
        <Typography id="remark-popup-title" variant="h6" component="h2">
          Remark
        </Typography>
        <Typography id="remark-popup-description" sx={{ mt: 2 }}>
          <TextField
            id="standard-multiline-static"
            label="Add or Edit Remark"
            multiline
            rows={4}
            value={editedRemark}
            onChange={handleRemarkChange}
            variant="standard"
            fullWidth
          />
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CustomButton
            onClick={onClose}
            variant={"outlined"}
            color="primary"
            style={{ marginTop: "20px" }}
          >
            Close
          </CustomButton>
          <CustomButton
            onClick={handleSave}
            variant={"outlined"}
            color="primary"
            style={{ marginTop: "20px" }}
          >
            Save
          </CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default RemarkPopup;
