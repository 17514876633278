import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Button,
  Input,
  MenuItem,
  Grid,
  CircularProgress,
} from "@mui/material";
import handleFileUpload from "../../api/axios/fileUpload";
import axios from "axios";

const PersonalLoanForm = ({
  setFormData,
  applicationId,
  setIsNextDisabled,
}) => {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();

  const [formData, setLocalFormData] = useState({
    jobType: "",
    companyName: "",
    jobTitle: "",
    yearsOfExperience: "",
    salaryDate: "",
    salaryAmount: "",
    payslip: "",
    bankStatement: "",
    adhaarCardImage: "",
    panCardImage: "",
  });
  const [invalidFields, setInvalidFields] = useState({});
  const [loadingStates, setLoadingStates] = useState({
    payslip: false,
    bankStatement: false,
    adhaarCardImage: false,
    panCardImage: false,
  });

  const [fileNames, setFileNames] = useState({
    payslip: "",
    bankStatement: "",
    adhaarCardImage: "",
    panCardImage: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLocalFormData({ ...formData, [name]: value });
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const handleFileChange = async (event, fieldName) => {
    const source = axios.CancelToken.source();
    setLoadingStates({ ...loadingStates, [fieldName]: true });
    const url = await handleFileUpload(
      event.target.files[0],
      source.token,
      `${applicationId}_${fieldName}`
    );
    setLoadingStates(false);
    setFormData({ ...formData, [fieldName]: url });
    setLocalFormData({ ...formData, [fieldName]: url });
    validateField([fieldName], url);
    setFileNames({ ...fileNames, [fieldName]: event.target.files[0].name });
  };

  const regexMap = {
    jobType: /^(SELF|SALARIED)$/,
    companyName: /^[A-Za-z0-9\s,-]+$/,
    jobTitle: /^[A-Za-z0-9\s,-]+$/,
    yearsOfExperience: /^\d+$/,
    salaryDate: /^[A-Za-z0-9\s,-]+$/,
    salaryAmount: /^\d+(\.\d{1,2})?$/,
    payslip: /.*/,
    bankStatement: /.*/,
    adhaarCardImage: /.*/,
    panCardImage: /.*/,
  };
  const validateField = (fieldName, value) => {
    const isValid = regexMap[fieldName]?.test(value);
    setInvalidFields({ ...invalidFields, [fieldName]: !isValid });
  };

  useEffect(() => {
    const shouldExcludeField = (fieldName) => {
      return (
        formData?.jobType === "SELF" &&
        (fieldName.toLowerCase().includes("payslip") ||
          fieldName.toLowerCase().includes("bankstatement"))
      );
    };
    const filteredInvalidFields = Object.keys(invalidFields).reduce(
      (acc, fieldName) => {
        if (!shouldExcludeField(fieldName)) {
          acc[fieldName] = invalidFields[fieldName];
        }
        return acc;
      },
      {}
    );
    const hasInvalidFields = Object.values(filteredInvalidFields).some(
      (invalid) => invalid
    );
    const filteredFormData = Object.keys(formData).reduce((acc, fieldName) => {
      if (!shouldExcludeField(fieldName)) {
        acc[fieldName] = formData[fieldName];
      }
      return acc;
    }, {});
    const areAllFieldsFilled =
      Object.keys(filteredInvalidFields).length ===
      Object.keys(filteredFormData).length;
    setIsNextDisabled(hasInvalidFields || !areAllFieldsFilled);
  }, [invalidFields, formData]);

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", py: 2 }}>
      <h2 style={{ marginBottom: "20px", fontFamily: "Poppins" }}>
        Employment Details
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="Job Type"
            name="jobType"
            value={formData.jobType}
            onChange={handleChange}
            required
            error={invalidFields.jobType}
            helperText={invalidFields.jobType && "Please select a job type"}
            sx={{ marginBottom: "20px" }}
          >
            {/* Dropdown options */}
            <MenuItem value="SELF">Self</MenuItem>
            <MenuItem value="SALARIED">Salaried</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Company Name"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
            error={invalidFields.companyName}
            helperText={
              invalidFields.companyName && "Please enter a company name"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Job Title"
            name="jobTitle"
            value={formData.jobTitle}
            onChange={handleChange}
            required
            error={invalidFields.jobTitle}
            helperText={invalidFields.jobTitle && "Please enter a job title"}
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Years of Experience"
            name="yearsOfExperience"
            value={formData.yearsOfExperience}
            onChange={handleChange}
            required
            error={invalidFields.yearsOfExperience}
            helperText={
              invalidFields.yearsOfExperience &&
              "Please enter years of experience"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Salary Date"
            type="date"
            name="salaryDate"
            value={formData.salaryDate || ""}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            required
            error={invalidFields.salaryDate}
            helperText={
              invalidFields.salaryDate && "Please enter a valid salary date"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Salary Amount"
            name="salaryAmount"
            value={formData.salaryAmount}
            onChange={handleChange}
            required
            error={invalidFields.salaryAmount}
            helperText={
              invalidFields.salaryAmount && "Please enter a valid salary amount"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>

        {formData.jobType !== "SELF" && (
          <>
            <Grid item xs={12} sm={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ marginBottom: "20px", display: "flex" }}>
                    <Box>
                      <Input
                        fullWidth
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf"
                        onChange={(event) => handleFileChange(event, "payslip")}
                        disableUnderline // Remove default underline
                        sx={{ display: "none" }} // Hide the default input
                        id="payslip1Input" // Associate with custom button
                      />
                      <label htmlFor="payslip1Input">
                        <Button
                          variant="outlined"
                          component="span"
                          sx={{ color: "#F37335", borderColor: "#F37335" }}
                        >
                          Upload Payslip of Last 3 Months
                        </Button>
                      </label>
                      <div style={{ color: "red" }}>
                        {"File Size should not exceed 15MB"}
                      </div>
                      {fileNames.payslip && (
                        <p>Uploaded file: {fileNames.payslip}</p>
                      )}
                    </Box>
                    {loadingStates.payslip && ( // Conditionally render loader while uploading
                      <Box
                        display="flex"
                        justifyContent="center"
                        marginBottom="20px"
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ marginBottom: "20px", display: "flex" }}>
                    <Box>
                      <Input
                        fullWidth
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf"
                        onChange={(event) =>
                          handleFileChange(event, "bankStatement")
                        }
                        disableUnderline // Remove default underline
                        sx={{ display: "none" }} // Hide the default input
                        id="bankStatement1Input" // Associate with custom button
                      />
                      <label htmlFor="bankStatement1Input">
                        <Button
                          variant="outlined"
                          component="span"
                          sx={{ color: "#F37335", borderColor: "#F37335" }}
                        >
                          Upload Bank Statement for last 3 Months
                        </Button>
                      </label>
                      <div style={{ color: "red" }}>
                        {"File Size should not exceed 15MB"}
                      </div>
                      {fileNames.bankStatement && (
                        <p>Uploaded file: {fileNames.bankStatement}</p>
                      )}
                    </Box>
                    {loadingStates.bankStatement && ( // Conditionally render loader while uploading
                      <Box
                        display="flex"
                        justifyContent="center"
                        marginBottom="20px"
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {/* Render common file upload fields */}
        <Grid item xs={12} sm={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ marginBottom: "20px", display: "flex" }}>
                <Box>
                  <Input
                    fullWidth
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    onChange={(event) =>
                      handleFileChange(event, "adhaarCardImage")
                    }
                    disableUnderline // Remove default underline
                    sx={{ display: "none" }} // Hide the default input
                    id="adhaarCardImageInput" // Associate with custom button
                  />
                  <label htmlFor="adhaarCardImageInput">
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{ color: "#F37335", borderColor: "#F37335" }}
                    >
                      Upload Adhaar Card Image
                    </Button>
                  </label>
                  <div style={{ color: "red" }}>
                    {"File Size should not exceed 5MB"}
                  </div>
                  {fileNames.adhaarCardImage && (
                    <p>Uploaded file: {fileNames.adhaarCardImage}</p>
                  )}
                </Box>
                {loadingStates.adhaarCardImage && ( // Conditionally render loader while uploading
                  <Box
                    display="flex"
                    justifyContent="center"
                    marginBottom="20px"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginBottom: "20px", display: "flex" }}>
                <Box>
                  <Input
                    fullWidth
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    onChange={(event) =>
                      handleFileChange(event, "panCardImage")
                    }
                    disableUnderline // Remove default underline
                    sx={{ display: "none" }} // Hide the default input
                    id="panCardImageInput" // Associate with custom button
                  />
                  <label htmlFor="panCardImageInput">
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{ color: "#F37335", borderColor: "#F37335" }}
                    >
                      Upload Pan Card Image
                    </Button>
                  </label>
                  <div style={{ color: "red" }}>
                    {"File Size should not exceed 5MB"}
                  </div>
                  {fileNames.panCardImage && (
                    <p>Uploaded file: {fileNames.panCardImage}</p>
                  )}
                </Box>
                {loadingStates.panCardImage && ( // Conditionally render loader while uploading
                  <Box
                    display="flex"
                    justifyContent="center"
                    marginBottom="20px"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalLoanForm;
