import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import BigLoader from "./components/BigLoader";
import SignIn from "./pages/SignIn";
import HomePage from "./pages/HomePage";
import ApplicationPage from "./pages/ApplicationPage";
import ApplicationsPage from "./pages/ApplicationsPage";
import TopNavBar from "./components/TopNavBar";
import { request } from "./api/requests";
import ContactUs from "./pages/ContactUs";
import { TEXTS_MAPPING } from "./utils/textMapping";
import { GetValueForKey } from "./utils/helper";

function App() {
  const fetchImagesAndTexts = async () => {
    const iamgesPromise = request.getImages();
    const textPromise = request.getTexts();

    const [images, texts] = await Promise.all([iamgesPromise, textPromise]);

    localStorage.setItem("images", JSON.stringify(images));
    localStorage.setItem("texts", JSON.stringify(texts));
    document.title = GetValueForKey(
      TEXTS_MAPPING.COMPANY_NAME,
      "text",
      true
    )?.[0];
  };

  useEffect(() => {
    fetchImagesAndTexts();
  }, []);

  document.title = "Loading....";

  return (
    <BrowserRouter>
      <>
        <TopNavBar />
        <React.Suspense fallback={<BigLoader />}>
          <Routes>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/application/:id" element={<ApplicationPage />} />
            <Route path="/applications" element={<ApplicationsPage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </React.Suspense>
      </>
    </BrowserRouter>
  );
}

export default App;
