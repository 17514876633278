import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Button,
  Input,
  CircularProgress,
  MenuItem,
  Grid,
} from "@mui/material";
import handleFileUpload from "../../api/axios/fileUpload";
import axios from "axios";
import { handleAlert } from "../../utils/handleAlert";

const PersonalDetailsForm = ({
  setFormData,
  applicationId,
  setIsNextDisabled,
}) => {
  const [formData, setLocalFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    adhaarNumber: "",
    homeAddress: "",
    homeAddressCity: "",
    homeAddressState: "",
    homeAddressPostalCode: "",
    homeAddressLandMark: "",
    maritalStatus: "",
    passportSizePicture: null,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [invalidFields, setInvalidFields] = useState({});
  const [uploadedFileName, setUploadedFileName] = useState("");
  const handleChange = (event) => {
    const { name, value } = event.target;
    setLocalFormData({ ...formData, [name]: value });
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const maxFileSize = 5 * 1024 * 1024;
    if (selectedFile.size > maxFileSize) {
      handleAlert("File size exceeds 5MB", "error");
    } else {
      const source = axios.CancelToken.source();
      setIsUploading(true);
      const url = await handleFileUpload(
        event.target.files[0],
        source.token,
        `${applicationId}_passportSizePicture`
      );
      setIsUploading(false);
      setFormData({ ...formData, passportSizePicture: url });
      setLocalFormData({ ...formData, passportSizePicture: url });
      validateField("passportSizePicture", url);
      setUploadedFileName(selectedFile.name);
    }
  };

  const regexMap = {
    fullName: /^[A-Za-z\s]+$/,
    phoneNumber: /^\d{10}$/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    adhaarNumber: /^\d{12}$/,
    homeAddress: /^[A-Za-z0-9\s,-]+$/,
    homeAddressCity: /^[A-Za-z\s]+$/,
    homeAddressState: /^[A-Za-z\s]+$/,
    homeAddressPostalCode: /^\d{6}$/,
    homeAddressLandMark: /^[A-Za-z0-9\s,-]+$/,
    maritalStatus: /^(SINGLE|MARRIED|DIVORCED|WIDOWED)$/,
    passportSizePicture: /.*/,
  };
  const validateField = (fieldName, value) => {
    const isValid = regexMap[fieldName]?.test(value);
    setInvalidFields({ ...invalidFields, [fieldName]: !isValid });
  };

  useEffect(() => {
    const hasInvalidFields = Object.values(invalidFields).some(
      (invalid) => invalid
    );
    const areAllFieldsFilled =
      Object.keys(invalidFields).length === Object.keys(formData).length;
    setIsNextDisabled(hasInvalidFields || !areAllFieldsFilled);
  }, [invalidFields]);

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", py: 2 }}>
      <h2
        style={{
          marginBottom: "20px",
          fontFamily: "Poppins",
          fontWeight: "400",
        }}
      >
        Personal Details
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Full Name"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            error={invalidFields.fullName}
            helperText={invalidFields.fullName && "Please enter a valid name"}
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Adhaar Number"
            name="adhaarNumber"
            value={formData.adhaarNumber}
            onChange={handleChange}
            required
            error={invalidFields.adhaarNumber}
            helperText={
              invalidFields.adhaarNumber && "Please enter a valid Aadhar number"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        {/* Add similar TextField components for other fields with validation */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            error={invalidFields.phoneNumber}
            helperText={
              invalidFields.phoneNumber && "Please enter a valid phone number"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            error={invalidFields.email}
            helperText={
              invalidFields.email && "Please enter a valid email address"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            label="Home Address"
            name="homeAddress"
            value={formData.homeAddress}
            onChange={handleChange}
            required
            error={invalidFields.homeAddress}
            helperText={
              invalidFields.homeAddress && "Please enter a valid home address"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Home Address City"
            name="homeAddressCity"
            value={formData.homeAddressCity}
            onChange={handleChange}
            required
            error={invalidFields.homeAddressCity}
            helperText={
              invalidFields.homeAddressCity && "Please enter a valid city name"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Home Address State"
            name="homeAddressState"
            value={formData.homeAddressState}
            onChange={handleChange}
            required
            error={invalidFields.homeAddressState}
            helperText={
              invalidFields.homeAddressState &&
              "Please enter a valid state name"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Home Address Postal Code"
            name="homeAddressPostalCode"
            value={formData.homeAddressPostalCode}
            onChange={handleChange}
            required
            error={invalidFields.homeAddressPostalCode}
            helperText={
              invalidFields.homeAddressPostalCode &&
              "Please enter a valid postal code"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Home Address Land Mark"
            name="homeAddressLandMark"
            value={formData.homeAddressLandMark}
            onChange={handleChange}
            required
            error={invalidFields.homeAddressLandMark}
            helperText={
              invalidFields.homeAddressLandMark &&
              "Please enter a valid landmark"
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="Marital Status"
            name="maritalStatus"
            value={formData.maritalStatus}
            onChange={handleChange}
            required
            error={invalidFields.maritalStatus}
            helperText={
              invalidFields.maritalStatus && "Please select a marital status"
            }
            sx={{ marginBottom: "20px" }}
          >
            <MenuItem value="SINGLE">Single</MenuItem>
            <MenuItem value="MARRIED">Married</MenuItem>
            <MenuItem value="DIVORCED">Divorced</MenuItem>
            <MenuItem value="WIDOWED">Widowed</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4} style={{ display: "flex" }}>
          <Box>
            <Input
              fullWidth
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              onChange={handleFileChange}
              disableUnderline // Remove default underline
              sx={{ display: "none" }} // Hide the default input
              id="passportSizePicture" // Associate with custom button
            />
            <label htmlFor="passportSizePicture">
              <Button
                variant="outlined"
                component="span"
                sx={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  color: "#F37335",
                  borderColor: "#F37335",
                }}
              >
                {formData.passportSizePicture ? "Update " : "Upload "}
                Passport Size Picture
              </Button>
            </label>
            <div style={{ color: "red" }}>
              {"File Size should not excced 5MB"}
            </div>
            {uploadedFileName && ( // Display the uploaded file name if available
              <p>Uploaded file: {uploadedFileName}</p>
            )}
          </Box>
          {isUploading && ( // Conditionally render loader while uploading
            <Box display="flex" justifyContent="center" marginBottom="20px">
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalDetailsForm;
