import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Input,
  CircularProgress,
} from "@mui/material";
import handleFileUpload from "../../api/axios/fileUpload";
import axios from "axios";
import { request } from "../../api/requests";
import { IMAGES_MAPPING } from "../../utils/imageMappings";
import { TEXTS_MAPPING } from "../../utils/textMapping";
import AddIcon from '@mui/icons-material/Add';
import { handleAlert } from "../../utils/handleAlert";

const UpdateDetailsPopup = ({ open, onClose }) => {
  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyCopyright, setCompanyCopyright] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAboutUs, setCompanyAboutUs] = useState("");
  const [footerText, setFooterText] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [blogImage, setBlogImage] = useState("");
  const [isBlogImageUploading, setIsBlogImageUploading] = useState(false);
  const [isCorousalImageUploading, setIsCorousalImageUploading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [contactUsBgImage, setContactUsBgImage] = useState("");
  const [carouselImages, setCarouselImages] = useState([]);
  const [companyLogoName, setCompanyLogoName] = useState("");
  const [companyBlogImageName, setCompanyBlogImageName] = useState("");
  const [corousalImageName, setCorousalImageName] = useState([]);
  const [bannerImage, setBannerImage] = useState("");
  const [isBannerImageUploading, setIsBannerImageUploading] = useState(false);
  const [bannerImageName, setBannerImageName] = useState("");
  const [companyMail, setCompanyMail] = useState("");

  const handleSave = async () => {
    await Promise.all([
      request.addImage(IMAGES_MAPPING.COMPANY_LOGO, companyLogo),
      request.addText(TEXTS_MAPPING.COMPANY_NAME, companyName),
      request.addText(TEXTS_MAPPING.COMPANY_COPYRIGHT_LINE, companyCopyright),
      request.addText(TEXTS_MAPPING.COMPANY_ADDRESS, companyAddress),
      request.addText(TEXTS_MAPPING.COMPANY_PHONE, companyPhone),
      request.addText(TEXTS_MAPPING.ABOUT_US, companyAboutUs),
      request.addText(TEXTS_MAPPING.OWNED_BY_TEXT_FOOTER, footerText),
      request.addText(TEXTS_MAPPING.COMPANY_FACEBOOK, facebookLink),
      request.addText(TEXTS_MAPPING.COMPANY_INSTAGRAM, instagramLink),
      request.addText(TEXTS_MAPPING.COMPANY_LINKEDIN, linkedinLink),
      request.addText(TEXTS_MAPPING.COMPANY_TWITTER, twitterLink),
      request.addText(TEXTS_MAPPING.COMPANY_EMAIL, companyMail),
      request.addImage(IMAGES_MAPPING.COMPANY_BLOG_IMAGE, blogImage),
      request.addImage(IMAGES_MAPPING.CONTACT_US_BG, contactUsBgImage),
      request.addImage(IMAGES_MAPPING.HOME_PAGE_BANNER, bannerImage),
      carouselImages.map((image, index) =>
        request.addImage(`${IMAGES_MAPPING.HOME_PAGE_BANNER_CAROUSEL}_${index}`, image)
      )
    ]);
    onClose();
  };

  const validateCarouselImages = async (files) => {
    const invalidImages = [];
    const promises = Array.from(files).map(async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            if (img.width < 1920 || img.height < 1080) {
              invalidImages.push(file.name);
            }
            resolve();
          };
          img.src = event.target.result;
        };
        reader.readAsDataURL(file);
      });
    });
    await Promise.all(promises);
    if (invalidImages.length > 0) {
      handleAlert(`The following images have invalid dimensions: ${invalidImages.join(', ')}. Please upload images with dimensions 1920px x 1080px.`, 'error');
      return false;
    }
    return true;
  };


  const handleAddCarousel = async (event) => {
    setIsCorousalImageUploading(true);
    const files = event.target.files;
    const isValid = await validateCarouselImages(files);
    if (!isValid) {
      setIsCorousalImageUploading(false);
      return;
    }
    const newCarouselImages = [...carouselImages];
    const newCarouselImageNames = Array.from(files).map(file => file.name);
    const source = axios.CancelToken.source();
    const uploadPromises = Array.from(files).map(async (file) => {
      const url = await handleFileUpload(file, source.token);
      newCarouselImages.push(url);
    });

    await Promise.all(uploadPromises);
    setIsCorousalImageUploading(false);
    if (files) {
      setCorousalImageName(prevNames => [...prevNames, ...newCarouselImageNames]);
    }
    setCarouselImages(newCarouselImages);

  };

  const handleFileChange = async (event, inputName) => {

    const source = axios.CancelToken.source();
    if (inputName === "companyLogo") {
      setIsUploading(true);
      const url = await handleFileUpload(event.target.files[0], source.token);
      setIsUploading(false);
      if (event.target.files[0].name) {
        setCompanyLogoName(event.target.files[0].name);
      }
      setCompanyLogo(url);
    } else if (inputName === "companyBlogImage") {
      setIsBlogImageUploading(true);
      const file = event.target.files[0];
      if (file.size < 500000) {
        setIsBlogImageUploading(false);
        return;
      }
      const url = await handleFileUpload(event.target.files[0], source.token);
      setIsBlogImageUploading(false);
      if (event.target.files[0].name) {
        setCompanyBlogImageName(event.target.files[0].name);
      }
      setBlogImage(url);
    }
    else if (inputName === "homePageBanner") {
      setIsBannerImageUploading(true);
      const url = await handleFileUpload(event.target.files[0], source.token);
      setIsBannerImageUploading(false);
      if (event.target.files[0].name) {
        setBannerImageName(event.target.files[0].name);
      }
      setBannerImage(url);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Company Details</DialogTitle>
      <DialogContent
        sx={{
          maxHeight: '430px'
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="companyName"
          label="Company Name"
          fullWidth
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="companyMail"
          label="Company Email"
          fullWidth
          value={companyMail}
          onChange={(e) => setCompanyMail(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="companyCopyRightLine"
          label="Copyright Line"
          fullWidth
          value={companyCopyright}
          onChange={(e) => setCompanyCopyright(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="companyAboutUs"
          label="About Us"
          fullWidth
          value={companyAboutUs}
          onChange={(e) => setCompanyAboutUs(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="companyAddress"
          label="Adress"
          fullWidth
          value={companyAddress}
          onChange={(e) => setCompanyAddress(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="companyPhoneNumber"
          label="Phone Number"
          fullWidth
          value={companyPhone}
          onChange={(e) => setCompanyPhone(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="ownedbyTextFooter"
          label="Footer Text"
          fullWidth
          value={footerText}
          onChange={(e) => setFooterText(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="companyLinkedLink"
          label="LinkedIn"
          fullWidth
          value={linkedinLink}
          onChange={(e) => setLinkedinLink(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="companyFaceebookLink"
          label="Facebook"
          fullWidth
          value={facebookLink}
          onChange={(e) => setFacebookLink(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="companyTwitterLink"
          label="Twitter"
          fullWidth
          value={twitterLink}
          onChange={(e) => setTwitterLink(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="companyInstagramLink"
          label="Instagram"
          fullWidth
          value={instagramLink}
          style={{ marginBottom: "10px" }}
          onChange={(e) => setInstagramLink(e.target.value)}
        />
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
          width: "100%",
        }}>
          <Box>
            <Input
              fullWidth
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              onChange={(e) => handleFileChange(e, "companyLogo")}
              disableUnderline // Remove default underline
              sx={{ display: "none", marginTop: "2px", }} // Hide the default input
              id="companyLogoInput" // Associate with custom button
            />
            <label htmlFor="companyLogoInput">
              <Button variant="outlined" component="span">
                Upload Company Logo
              </Button>

            </label>
            {isUploading && (
              <CircularProgress sx={{ marginLeft: "10px" }} size={20} />
            )}
          </Box>
          {
            companyLogoName && <span>{companyLogoName}</span>
          }
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
        }}>
          <Box>
            <Input
              fullWidth
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              onChange={(e) => handleFileChange(e, "companyBlogImage")}
              disableUnderline // Remove default underline
              sx={{ display: "none", marginTop: "2px" }} // Hide the default input
              id="companyBlogImageInput" // Associate with custom button
            />
            <label htmlFor="companyBlogImageInput">
              <Button variant="outlined" component="span">
                Upload Company Blog Image
              </Button>

            </label>
            {isBlogImageUploading && (
              <CircularProgress sx={{ marginLeft: "10px" }} size={20} />
            )}
          </Box>
          {
            companyBlogImageName && <span>{companyBlogImageName}</span>
          }
          <div style={{ color: "red" }}>
            {"Please upload image with size greater than 500kb"}
          </div>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
        }}>
          <Box>
            <Input
              fullWidth
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              onChange={(e) => handleFileChange(e, "homePageBanner")}
              disableUnderline // Remove default underline
              sx={{ display: "none", marginTop: "2px", }} // Hide the default input
              id="homePageBannerInput" // Associate with custom button
            />
            <label htmlFor="homePageBannerInput">
              <Button variant="outlined" component="span">
                Upload Banner Image
              </Button>

            </label>
            {isBannerImageUploading && (
              <CircularProgress sx={{ marginLeft: "10px" }} size={20} />
            )}
          </Box>
          {
            bannerImageName && <span>{bannerImageName}</span>
          }
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
        }}>
          <Box>
            <Input
              fullWidth
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              onChange={handleAddCarousel}
              disableUnderline // Remove default underline
              sx={{ display: "none", marginTop: "2px" }} // Hide the default input
              id="addCarouselImageInput" // Associate with custom button
              multiple // Allow multiple file selection
            />
            <label htmlFor="addCarouselImageInput">
              <Button variant="outlined" component="span" >
                <AddIcon />
                Add Carousel Images
              </Button>
            </label>
            {isCorousalImageUploading && (
              <CircularProgress sx={{ marginLeft: "10px" }} size={20} />
            )}
          </Box>
          {
            corousalImageName && corousalImageName.map((name, index) =>
              <span key={index}>{name}</span>
            )
          }
          <div style={{ color: "red" }}>
            {"Please upload images with dimensions 1920px x 1080px"}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDetailsPopup;
