import React, { useState, useEffect } from "react";
import { Stepper, Step, StepLabel, Paper, Button, Box } from "@mui/material";
import PersonalDetailsForm from "../../components/PersonalDetailsForm";
import PersonalLoanForm from "../../components/PersonalLoanForm";
import BusinessLoanForm from "../../components/BusinessLoanForm";
import HomeLoanForm from "../../components/HomeLoanForm";
import AddressProofInfoForm from "../../components/AddressInfoForm";
import { request } from "../../api/requests";
import { useParams } from "react-router-dom";
import MessagePopup from "../../components/MessagePopup";
import CheckIcon from "@mui/icons-material/Check";
const LoanApplication = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loanType, setLoanType] = useState("");
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const { id: applicationId } = useParams();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setLoanType(urlParams.get("loanType"));
  }, []);

  const steps = [
    "Personal Details",
    loanType === "PERSONAL"
      ? "Employment Details"
      : loanType === "BUSINESS"
      ? "Business Details"
      : "Home Loan Details",
    "Address & Loan Details",
  ];
  const CustomStepIcon = ({ step, active, completed }) => {
    return (
      <div
        style={{
          position: "relative",
          width: 24,
          height: 24,
          borderRadius: "50%",
          background: "#FDC830",
          background: "-webkit-linear-gradient(to right, #F37335, #FDC830)",
          background: "linear-gradient(to right, #F37335, #FDC830)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!completed && (
          <span style={{ color: "white", position: "absolute", zIndex: 1 }}>
            {step}
          </span>
        )}
        <CheckIcon
          style={{
            color: "white",
            zIndex: 1,
            display: completed ? "block" : "none",
          }}
        />
      </div>
    );
  };

  const stepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0: {
        return (
          <PersonalDetailsForm
            setFormData={setFormData}
            applicationId={applicationId}
            setIsNextDisabled={setIsNextDisabled}
          />
        );
      }
      case 1:
        if (loanType === "PERSONAL") {
          return (
            <PersonalLoanForm
              formData={formData}
              setFormData={setFormData}
              applicationId={applicationId}
              setIsNextDisabled={setIsNextDisabled}
            />
          );
        } else if (loanType === "BUSINESS") {
          return (
            <BusinessLoanForm
              formData={formData}
              setFormData={setFormData}
              applicationId={applicationId}
              setIsNextDisabled={setIsNextDisabled}
            />
          );
        } else {
          return (
            <HomeLoanForm
              formData={formData}
              setFormData={setFormData}
              applicationId={applicationId}
              setIsNextDisabled={setIsNextDisabled}
            />
          );
        }
      case 2: {
        return (
          <AddressProofInfoForm
            formData={formData}
            setFormData={setFormData}
            applicationId={applicationId}
            setIsNextDisabled={setIsNextDisabled}
          />
        );
      }
      default:
        setShowModal(true);
    }
  };

  const handleNext = async () => {
    setActiveStep(activeStep + 1);
    await request.updateApplication(applicationId, formData);
    if (activeStep === steps.length - 1) {
      setShowModal(true);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Paper variant="outlined" sx={{ padding: 2 }}>
      <Box sx={{ position: "relative" }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ fontFamily: "Poppins" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={() => (
                  <CustomStepIcon
                    step={index + 1}
                    active={activeStep === index}
                    completed={activeStep > index}
                  />
                )}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {showModal ? (
          <MessagePopup open={showModal} handleClose={handleCloseModal} />
        ) : (
          <>
            {stepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
              }}
            >
              <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{
                  marginRight: 1,
                  background: "#FDC830",
                  background:
                    "-webkit-linear-gradient(to right, #F37335, #FDC830)",
                  background: "linear-gradient(to right, #F37335, #FDC830)",
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={isNextDisabled} // Disable next button if required fields are not filled
                sx={{
                  background: "#FDC830",
                  background:
                    "-webkit-linear-gradient(to right, #F37335, #FDC830)",
                  background: "linear-gradient(to right, #F37335, #FDC830)",
                }}
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default LoanApplication;
